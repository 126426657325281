import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom"; // 导入useLocation
import styled from "styled-components";
import api from "../api";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';
import LoadingModal from '../utils/LoadingModal';
import { initOssUpload } from '../utils/upload';
const Styles = styled.div`
  background: lavender;
  padding: 20px;

  h1 {
    border-bottom: 1px solid white;
    color: #3d3d3d;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    padding: 10px;
    text-align: center;
  }

  h2 {
    text-align: center; /* 标题居中显示 */
  }
  
  .guidance {
    font-weight: bold; /* 设置粗体 */
    margin-bottom: 15px;
  }

  .pointItem {
    margin-bottom: 8px; /* 调整适中间距 */
    padding-left: 20px; /* 略微缩进以突出显示 */
  }

  .evaluation-title {
    font-weight: bold; /* 设置粗体 */
    margin-bottom: 10px;
    font-size: 18px; /* 可以调整字体大小以进一步增强标题的突出效果 */
  }

  .evaluation-item {
    background: white;
    border: 1px solid #dedede;
    border-radius: 8px; /* 圆角边框 */
    margin: 20px auto; /* 添加居中及顶部和底部的间距 */
    padding: 20px; /* 内部填充 */
    width: 80%; /* 占据整个页面的80%宽度 */
  }

  textarea {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    min-height: 100px;
    resize: vertical;
  }

  label {
    color: #3d3d3d;
    display: block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .error {
    color: red;
    font-family: sans-serif;
    font-size: 12px;
    height: 30px;
  }

  .submitButton {
    background-color: #6976d9;
    color: white;
    font-family: sans-serif;
    font-size: 14px;
    margin: 20px 0;
  }

  @media (max-width: 600px) {
    .evaluation-item {
      padding: 15px;
    }
  }

  
`;

const UserInfoContainer = styled.div`
  position: fixed; // 使用fixed定位确保用户信息始终显示在页面的特定位置
  top: 20px; // 距离页面顶部20px
  right: 20px; // 距离页面右侧20px
  padding: 10px 20px; // 内部的上下左右填充
  background-color: #f0f0f0; // 背景色
  border: 1px solid #ddd; // 边框
  border-radius: 10px; // 边框圆角
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // 阴影效果
  text-align: right; // 文本右对齐
  font-size: 18px; // 字体大小
  color: #3d3d3d; // 字体颜色
  z-index: 1000; // 确保它在页面上的其他元素之上
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; // 居中按钮
  gap: 20px; // 设置按钮之间的间距
  margin-top: 20px; // 与上面内容的间距
`;

const StyledButton = styled.button`
  padding: 10px 20px; // 增加padding使按钮更大
  font-size: 18px; // 增大字体大小
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #6976d9;
  color: white;
  &:disabled {
    background-color: #ccc; // 可选：禁用状态下的按钮颜色
  }
`;



const EvaluationFormPreview = ({ kindergartenId: propKindergartenId }) => {
  const location = useLocation(); // 使用useLocation钩子
  const navigate = useNavigate(); // 创建导航函数
  const [isLoading, setIsLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(null);
  const [allFilesUploaded, setAllFilesUploaded] = React.useState(false);
  const [triggerSave, setTriggerSave] = React.useState(false);

  let { teacherId, kindergartenId: stateKindergartenId, realName } = location.state || {};
  
  // 使用 propKindergartenId 或 stateKindergartenId
  const kindergartenId = propKindergartenId || stateKindergartenId;
  console.log("kindergartenId:", kindergartenId);
  if (!kindergartenId) {
    // kindergartenId = "659708a9eee2a90b5a473dac"; // 如果没有传递teacherId，则使用默认值
    navigate('/login'); // 跳转到登录页面
  }

  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
  const [evaluationData, setEvaluationData] = React.useState({});
  const [canEdit, setCanEdit] = React.useState(false); // 默认可以编辑
  const [kindergartenName, setKindergartenName] = React.useState(""); // 用于存储幼儿园名称
  // const year = new Date().getFullYear(); // 或者从其他地方获取
  const year = 2025;



  const handleLogout = () => {
    // 这里可以添加登出逻辑，例如清除用户状态、发送登出请求等
    navigate('/login'); // 跳转到登录页面
  };

  const onSubmit = async (data, isSubmit) => {
    const payload = {
      kindergartenId,
      year,
      evaluationData: data.evaluations.map((evaluation, index) => ({
        ...evaluation,
        attachments: uploadedFiles[index] || [] // 确保按评估项ID匹配附件
      })),
      finished: isSubmit,
    };

    // console.log(payload);
    try {
      // 发送请求到后端
      const response = await api.saveEvaluation(payload);
      if (response.status === 200) {
        toast.success(isSubmit ? '评价提交成功！' : '评价保存成功！');
        setCanEdit(isSubmit);
      } else {
        toast.error('操作失败，请重试。');
      }
    } catch (error) {
      console.error("操作出错:", error);
      toast.error('操作出错，请重试。');
    } finally {
      setIsLoading(false); // 无论成功还是失败，都要隐藏加载模态框
    }
  };


  const handleSave = formData => {
    onSubmit(formData, false);
  };

  const handleSubmission = data => {
    // 弹出确认窗口
    const isConfirmed = window.confirm("提交后无法再次修改，您确定要提交吗？");

    if (isConfirmed) {
      // 如果用户确认，继续提交表单
      onSubmit(data, true);
    } else {
      // 如果用户取消，不提交表单
      toast.info("提交已取消"); // 可选：给用户一个反馈
    }
  };

  const handleRemoveFile = async (itemId, fileName) => {
    const payload = {
      kindergartenId,
      fileName
    };
    const ret = await api.deleteFile(payload);
    if (ret.status === 200) {
      setUploadedFiles(prevState => {
        // 正确计算当前项的索引
        const currentIndex = itemId;
        const updatedFiles = prevState[currentIndex].filter(file => file.name !== fileName);
        // console.log('updatedFiles:', updatedFiles);
        return { ...prevState, [currentIndex]: updatedFiles };
      });
      // 设置触发保存的标志
      setTriggerSave(true);
    }
  };




  // // 新增状态来跟踪每个评估项上传的文件
  const [uploadedFiles, setUploadedFiles] = React.useState({});
  const evaluationItems = [
    {
      id: 0,
      title: "评估指标1.办园方向之党建工作：强化党组织战斗堡垒作用",
      guidance: "自评要点：",
      points: [
        "举证说明党的组织建设、制度建设和工作机制；",
        "举证说明领导班子建设情况；",
        "举证说明工会、共青团等群团组织的作用；",
        "举证说明党支部参与幼儿园发展规划、年度工作计划的制定情况。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 1,
      title: "评估指标2.办园方向之品德启蒙：全面贯彻党的教育方针，落实立德树人根本任务。",
      guidance: "自评要点：",
      points: [
        "有哪些做法体现了把社会主义核心价值观融入保育教育全过程",
        "培育幼儿良好品德和行为习惯养成的具体举措",
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "①本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "②此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "③目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 2,
      title: "评估指标3.办园方向之科学理念：树立科学保育教育理念，确保正确办园方向。",
      guidance: "自评要点：",
      points: [
        "举证说明幼儿园保教活动如何做到遵循幼儿身心发展规律和学前教育规律；",
        "举证说明幼儿园如何做到坚持以游戏为基本活动；",
        "举证说明幼儿园如何做到支持和满足幼儿学习与发展的需要。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）。", answer: "" },
      ]
    },
    {
      id: 3,
      title: "评估指标4.保育与安全之卫生保健：加强膳食营养、疾病预防、健康检查等工作，强化医护保健人员配备。",
      guidance: "自评要点：",
      points: [
        "卫生保健工作制度的完善和落实；",
        "举证说明如何确保幼儿膳食营养均衡；",
        "传染病的防控及应对措施；",
        "卫生保健人员的资质与配备。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 4,
      title: "评估指标5.保育与安全之生活照料：建立合理的生活常规。",
      guidance: "自评要点：",
      points: [
        "结合实例说明如何引导幼儿养成良好的生活卫生习惯；",
        "结合实例说明如何引导幼儿形成自我服务能力和养成劳动习惯；",
        "说明体格锻炼活动的时间和内容；",
        "对有特殊需要幼儿（例如自闭倾向或攻击倾向的幼儿）照料的可用资源（包括人力配备、场地和设施等）和预案。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 5,
      title: "评估指标6.保育与安全之安全防护：安全保障和制度落实，确保幼儿生命安全和身心健康。",
      guidance: "自评要点：",
      points: [
        "列出安全管理制度并说明具体措施；",
        "列出环境、设施设备等日常检查维护的相关制度；",
        "结合实例说明如何把安全教育融入幼儿一日生活。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 6,
      title: "评估指标7.教育过程之活动组织：坚持以游戏为基本活动。",
      guidance: "自评要点：",
      points: [
        "各班级保教计划制定的依据、过程和制度；",
        "说明各年龄段幼儿一日活动的时间和内容安排；",
        "游戏时间的安排、游戏环境的创设和游戏材料的提供；",
        "为鼓励幼儿自主探究、自主思考、积极表达，采取了哪些措施，有何成效和体现；",
        "列出幼儿园当前主要参考或使用的课程教学类资源，如何体现了五领域整合；",
        "结合实例说明教师如何做到寓教育于生活和游戏中；",
        "列出大班下学期组织的入学准备活动。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 7,
      title: "评估指标8.教育过程之师幼互动：理解尊重幼儿并支持其有意义地学习。",
      guidance: "自评要点：",
      points: [
        "以具体活动为例说明教师与幼儿在活动中的情绪状态；",
        "结合实例说明教师如何支持幼儿自主选择和做决策；",
        "结合实例说明教师如何进行幼儿行为的观察分析与指导，关于每个幼儿行为特点和成长变化的观察记录的频次和数量如何，是否有形成记录存档；",
        "结合实例说明教师如何善于发现教育契机有效支持幼儿的学习，重视幼儿活动前的计划环节和活动后的总结环节；",
        "结合实例说明教师如何尊重并回应幼儿的想法与问题；",
        "结合实例说明教师如何尊重幼儿发展的个体差异；",
        "实例说明教师如何促进幼儿在原有水平上的全面发展;"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 8,
      title: "评估指标9.教育过程之家园共育：强化家园协同育人。",
      guidance: "自评要点：",
      points: [
        "建设家园关系的具体举措；",
        "如何通过制度化活动让家长参与和支持幼儿园工作；",
        "多途径开展对家长进行科学育儿指导的制度、举措等；",
        "幼儿园与家庭、社区的协同育人机制及具体做法。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 9,
      title: "评估指标10.环境创设之空间设施：积极创设丰富适宜、富有童趣、有利于支持幼儿学习探索的教育环境。",
      guidance: "自评要点：",
      points: [
        "用数字说明幼儿园规模与班额；",
        "用面积数据和图片说明班级空间的布局与利用；",
        "列出班级区域活动空间（含走廊）的面积和功能；",
        "列出户外场地的设计与利用情况；",
        "举证说明幼儿园设施设备满足幼儿学习与游戏需要的情况。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 10,
      title: "评估指标11.环境创设之玩具材料：配备数量充足、种类多样的玩教具和图画书，有效支持保育教育工作科学实施。",
      guidance: "自评要点：",
      points: [
        "说明玩具材料的种类、数量、利用率；",
        "说明图画书的数量、调整更新周期和利用率。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 11,
      title: "评估指标12.教师队伍之师德师风：加强教师师德工作。",
      guidance: "自评要点：",
      points: [
        "说明师德师风相关的制度和要求；",
        "列出本园开展的师德师风建设活动；",
        "列出本园教师获得的与师德师风相关的表彰奖励；",
        "列出本园对教职工开展人文关怀和各种帮扶的举措。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 12,
      title: "评估指标13.教师队伍之人员配备： 符合《幼儿园教职工配备标准》（暂行）的要求。",
      guidance: "自评要点：",
      points: [
        "列出各类教职工配备的数量；",
        "说明教职工持证上岗情况；",
        "说明保教人员的学历和所持证书情况；",
        "列出本园教师获得市级以上名师、名园长或同类荣誉称号的人次及名称；",
        "统计近三年专任教师的离职率；",
        "说明园长的工作经历并举例说明园长的专业领导力表现。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 13,
      title: "评估指标14.教师队伍之专业发展：注重教师专业能力建设，提高园长专业领导力。",
      guidance: "自评要点：",
      points: [
        "举证说明教师专业发展规划的制订与落实；",
        "说明园本教研制度、教研活动内容和频率；",
        "统计过去一整年里教师平均培训学时、教师培训经费占年度公用经费的百分比；",
        "活命本园教师每年去其它幼儿园观摩的人次；",
        "举证说明幼儿园对教师专业发展的支持措施。",
        "举证说明园长参与教研活动的次数、时间、内容、形式及成效。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },
    {
      id: 14,
      title: "评估指标15.教师队伍之激励机制：采取有效措施激励教师爱岗敬业、潜心育人。",
      guidance: "自评要点：",
      points: [
        "说明对教师进行激励的方式、措施；",
        "举例说明教职工感受到的关心与支持；",
        "说明各层级教师的大约年均总收入；",
        "说明各类教职工的社保缴费情况；",
        "说明幼儿园各种表彰奖项的设置、数量、频次。"
      ],
      wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
      questions: [
        { questionText: "本园当前现状写实（限600字以内）", answer: "" },
        { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
        { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
      ]
    },

  ];

  useEffect(() => {
    const fetchEvaluationData = async () => {
      try {
        // 直接使用改正后的API调用方法
        const response = await api.getEvaluations(kindergartenId, year);
        if (response.data && response.data.success) {
          const fetchedData = response.data.data;
          setEvaluationData(fetchedData.evaluationData.dimensions); // 存储评估维度数据
          setCanEdit(fetchedData.finished); // 根据finished字段设置canEdit状态
          setKindergartenName(fetchedData.kindergartenName)
          // 初始化表单数据
          fetchedData.evaluationData.dimensions.forEach((dimension, index) => {
            dimension.questions.forEach((question, qIndex) => {
              setValue(`evaluations[${index}].questions[${qIndex}].answer`, question.answer);
            });

            // 初始化已上传的文件
            if (dimension.attachments && dimension.attachments.length > 0) {
              setUploadedFiles(prevState => ({
                ...prevState,
                [index]: dimension.attachments.map(attachment => ({
                  name: attachment.name,
                }))
              }));
            }
          });
        }
      } catch (error) {
        setCanEdit(false);
        // 清空所有问题的答案
        evaluationItems.forEach((dimension, index) => {
          dimension.questions.forEach((_, qIndex) => {
            setValue(`evaluations[${index}].questions[${qIndex}].answer`, "");
          });
        });
        // 清空附件列表
        setUploadedFiles({});
        console.error("加载评估数据出错:", error);
      }
    };

    fetchEvaluationData();
  }, [kindergartenId, year, setValue]);

  useEffect(() => {
    evaluationItems.forEach((item, index) => {
      // 假设每个题目的上传按钮和容器ID如下形式生成
      const browseButtonId = `selectfiles-${item.id}`;
      const containerId = `container-${item.id}`;
      initOssUpload(browseButtonId, containerId, item.id, kindergartenId, setUploadedFiles, setAllFilesUploaded);
    });

  }, [kindergartenId]);

  useEffect(() => {
    // 当allFilesUploaded为true，触发保存逻辑
    if (allFilesUploaded) {
      // 确保在此处正确调用保存逻辑
      const formData = getValues();
      handleSave(formData);
      // 重置allFilesUploaded状态以便下一次上传
      setAllFilesUploaded(false);
    }
  }, [allFilesUploaded, getValues, handleSave]);

  useEffect(() => {
    if (triggerSave) {
      // 获取当前表单的值并保存
      const formData = getValues();
      handleSave(formData);

      // 重置triggerSave状态，以便于下一次触发
      setTriggerSave(false);
    }
  }, [triggerSave, getValues, handleSave]);


  return (
    <Styles>
      <ToastContainer />
      {/* <UserInfoContainer>
        {realName} - {kindergartenName}
        <button onClick={handleLogout} style={{ marginLeft: '20px', padding: '5px 10px', fontSize: '16px', cursor: 'pointer', borderRadius: '5px', border: 'none', backgroundColor: '#6976d9', color: 'white' }}>
          登出
        </button>
        <p style={{ marginTop: '10px', fontSize: '16px', color: canEdit ? '#2e7d32' : '#d32f2f' }}>
          状态：{!canEdit ? "可以编辑" : "已提交，禁止编辑"}
        </p>
      </UserInfoContainer> */}
      <form onSubmit={handleSubmit(handleSubmission)}>
        {evaluationItems.map((item, index) => (
          <div key={item.id} className="evaluation-item">
            <h2>{item.title}</h2>
            <p>{item.guidance}</p>
            {item.points.map((point, pIndex) => (
              <p key={pIndex} className="pointItem">{point}</p>
            ))}
            {item.questions.map((question, qIndex) => (
              <div key={qIndex}>
                <label htmlFor={`question-${item.id}-${qIndex}`}>{question.questionText}</label>
                <textarea
                  id={`question-${item.id}-${qIndex}`}
                  {...register(`evaluations[${index}].questions[${qIndex}].answer`, { required: false })}
                  disabled={canEdit} // 根据canEdit状态禁用或启用输入框
                />
              </div>
            ))}
            <div id={`container-${item.id}`} className="upload-container">
              <p style={{ color: 'red' }}>最多支持20个附件，格式包括图片、word、pdf。可提交一个不超过3分钟的视频（视频大小60MB以内）
                视频内容为某要考察点的相关视频(如，某些教育活动的视频节选)
                提交时，需将视频名称改为考察要点的内容，如果没有可以不提交。</p>
              {/* <button id={`selectfiles-${item.id}`} type="button" className="upload-button" disabled={canEdit}>选择文件</button>
              <button id={`postfiles-${item.id}`} type="button" className="upload-button" disabled={canEdit}>开始上传</button> */}
              <div id={`ossfile-${item.id}`}>上传状态显示区域</div>
            </div>
            {/* 附件显示区域 */}
            <div className="attachments-container">
              {uploadedFiles[item.id] && uploadedFiles[item.id].map((file, fileIndex) => (
                <div key={fileIndex} className="attachment-item">
                  <span>{file.name.split('/').pop()}</span> {/* 只显示文件名 */}
                  {/* <button type="button" onClick={() => handleRemoveFile(item.id, file.name)} disabled={canEdit}>删除</button> */}
                </div>
              ))}
            </div>
          </div>
        ))}
        {/* <ButtonContainer>
          <StyledButton id="saveButton" type="button" onClick={handleSubmit(handleSave)} disabled={canEdit}>保存</StyledButton>
          <StyledButton type="submit" disabled={canEdit}>提交</StyledButton >
        </ButtonContainer> */}
      </form>
      <LoadingModal isLoading={isLoading} />
    </Styles>
  );
}

export default EvaluationFormPreview;